.background {
    min-height: 100vh;
    min-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background:
            url('../../../public/img/top-wave.svg') no-repeat top right,
            url('../../../public/img/bottom-wave.svg') no-repeat bottom left,
            var(--clf-color-main);
}

.content {
    z-index: 6;
    display: flex;
    flex-flow: column;
    gap: 24px;
    width: 400px;
}
.content:focus {
    outline: 0;
}

.panel {
    display: flex;
    flex-flow: column;
    gap: 32px;
    padding: 40px;
    border-radius: 2px;
    background-color: #fff;
}

.copyright {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: 12px;
    font: var(--clf-font-main);
    color: #fff;
}

.copyright > br {
    content: '';
    border: 0;
    border-left: 1px solid #fff;
}

.copyright > a {
    color: inherit;
    text-decoration: underline;
}

.logo {
    position: absolute;
    top: 40px;
    left: 40px;
    z-index: 5;
}

/*---- below go styles for various landing pages ----*/

.productLogo {
    margin-bottom: 8px;
    align-self: center;
    width: 252px;
    height: 43px;
}

.titleBlock {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 24px;
}

.form {
    display: flex;
    flex-flow: column;
    gap: 16px;
}

.or {
    display: flex;
    align-items: center;
    gap: 12px;
    font: var(--clf-font-main);
    color: var(--clf-text-color-secondary);
}
.or:before, .or:after {
    content: '';
    border-top: 1px solid var(--clf-border-color-primary);
    flex-grow: 1;
}

.k1Button:before {
    content: '';
    width: 24px;
    height: 18px;
    background: url('../../../public/img/k1-logo.svg') no-repeat center / contain;
}

.link {
    align-self: center;
    font: var(--clf-font-main);
    color: var(--clf-color-information);
    text-decoration: none;
    cursor: pointer;
}
.link:hover {
    text-decoration: underline;
}

.list {
    display: flex;
    flex-flow: column;
    gap: 12px;
    list-style-position: inside;
    margin: 0;
    padding: 0;
    font: var(--clf-font-main);
    color: var(--clf-text-color-secondary);
}

.qrError {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 12px;
    font: var(--clf-font-main);
    color: var(--clf-color-critical);
    text-align: center;
}

.qrCode {
    display: block;
    height: 127px;
    width: 133px;
    margin: 0 auto;
}