.main {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    width: 240px;
    border-right: 1px solid rgba(229, 229, 229, 1);
    background: #fff;
    transition: width .3s;
}

.collapsed {
    width: 56px;
}

.toggle {
    position: absolute;
    right: -12px;
    top: 32px;
    z-index: 1;
    width: 24px;
    height: 24px;
    border: 1px solid rgba(229, 229, 229, 1);
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
}

.toggle:hover {
    background-color: rgba(246, 247, 248, 1);
}

.link {
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 12px;
    min-height: 32px;
    padding-left: 16px;
    border-left: 4px solid transparent;
    font: 400 12px /20px Roboto, sans-serif;
    color: rgba(0, 29, 48, 1);
    white-space: nowrap;
    text-decoration: none;
    transition: all 0.1s linear 0.01s;
}

.link > svg {
    flex-shrink: 0;
    flex-grow: 0;
}

.link:hover {
    background-color: rgba(246, 247, 248, 1);
}

.link.active {
    border-left-color: rgba(55, 102, 103, 1);
    background-color: rgba(219, 237, 238, 1);
    font-weight: 700;
}

.link > span {
    overflow: hidden;
    width: 100%;
    transition: width .3s;
}

.collapsed .link > span {
    width: 0;
}

.collapsed > .toggle {
    position: static;
    margin: 8px 16px;
    transform: rotate(180deg);
}