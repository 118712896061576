.wrap {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font: 400 12px/20px Roboto;
}

.hint {
    position: absolute;
    left: 0;
    top: 32px;
    padding-left: 8px;
    font: 400 12px/20px Roboto;
    color: var(--clf-text-color-secondary);
}

.main {
    width: 100%;
    border: 1px solid var(--clf-border-color);
    border-radius: 4px;
    padding: 4px 8px;
    background-color: #FFFFFF;
    font: var(--clf-font-main);
    outline: none;
    text-overflow: ellipsis;
}

input.main {
    height: 32px;
}

.select {
    height: 32px;
    padding: 3px 8px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
}

.select > * {
    flex-shrink: 0;
    flex-grow: 0;
}

.select > input {
    flex-shrink: 1;
    flex-grow: 1;
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
}

.iconButton {
    position: absolute;
    right: 8px;
    transition: all .2s;
    font: var(--clf-font-main);
    cursor: pointer;
}

.main:hover {
    border-color: var(--clf-border-color-hovered);
}

.main:focus-within {
    border-color: var(--clf-color-main);
}

input.main:read-only, .main.readOnly {
    border-color: var(--clf-background-color-disabled);
    background-color: var(--clf-background-color-disabled) !important;
}

.main:disabled, .main.disabled {
    border-color: var(--clf-border-color-disabled);
    background-color: var(--clf-background-color-disabled) !important;
}

.main.invalid {/*.main:invalid, */
    border-color: var(--clf-color-critical);
}

.main.invalid + .hint {/*.main:invalid + .hint, */
    color: var(--clf-color-critical);
}

.pseudoReadOnly {
    pointer-events: none;
}

.withIcon {
    padding-right: 26px;
}