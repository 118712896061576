@keyframes show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.main {
    position: absolute;
    display: flex;
    flex-flow: column;
    gap: 4px;
    padding: 4px 8px;
    border-radius: 4px;
    background: var(--clf-text-color);
    font: var(--clf-font-submain);
    color: #fff;
    box-shadow: var(--clf-shadow-sm);
    animation: show .2s ease-in-out;
}

.ignore {
    pointer-events: none;
}