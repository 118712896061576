.main {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 16px;
    padding: 16px;
    border: 0 solid transparent;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow: var(--clf-shadow-sm);
    font: var(--clf-font-main);
}

.main.hasBorder {
    border-left-width: 5px;
}

.main.block {
    box-shadow: none;
}

.main.information {
    background-color: #f0f1ff;
    border-color: var(--clf-color-information);
}

.main.success {
    background-color: #dffbeb;
    border-color: var(--clf-color-success);
}

.main.warning {
    background-color: #fff1c7;
    border-color: var(--clf-color-warning);
}

.main.critical {
    background-color: #ffd7d7;
    border-color: var(--clf-color-critical);
}

.icon {
    flex-shrink: 0;
    flex-grow: 0;
}

.text {
    width: 100%;
}

.close {
    flex-shrink: 0;
    flex-grow: 0;
    color: var(--clf-text-color-secondary);
    cursor: pointer;
}