.main {
    display: flex;
    flex-flow: column;
    gap: 8px;
}

.label {
    order: -1;
    font: var(--clf-font-main-bolder);
    color: var(--clf-text-color-secondary);
}

.required > .label:after, [data-required="true"] + .label:after {
    content: '*';
    margin-left: 4px;
    color: var(--clf-color-critical);
}