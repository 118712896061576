.wrap {
    vertical-align: middle;
    align-self: flex-start;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 9px;
    height: 20px;
    min-width: 20px;
    font: var(--clf-font-submain);
    white-space: nowrap;
    cursor: pointer;
}

.wrap > input {
    flex-shrink: 0;
    flex-grow: 0;
    width: 14px;
    height: 14px;
    margin: 3px;
    opacity: 0;
    cursor: pointer;
}

.wrap > .main {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -26px;
    width: 14px;
    height: 14px;
    border: 1px solid var(--clf-border-color);
    border-radius: 2px;
    background: #fff;
    pointer-events: none;
}
.wrap:hover > .main {
    border-color: var(--clf-border-color-hovered);
}

.wrap > input:checked + .main {
    border-color: var(--clf-color-main);
    background-color: var(--clf-color-main);
}

.wrap > input:checked + .main:before {
    content: '';
    width: 10px;
    height: 6px;
    border: 2px solid #fff;
    border-top: 0;
    border-right: 0;
    margin-top: -3px;
    transform: rotate(-45deg);
}

.wrap > input:indeterminate + .main {
    border-color: var(--clf-color-main);
    background-color: var(--clf-color-main);
}

.wrap > input:indeterminate + .main:before {
    content: '';
    width: 8px;
    height: 2px;
    border-radius: 1px;
    background: #fff;
}

.wrap:hover > input:checked + .main {
    border-color: var(--clf-color-main-hovered);
    background-color: var(--clf-color-main-hovered);
}

.wrap:hover > input:indeterminate + .main {
    border-color: var(--clf-color-main-hovered);
    background-color: var(--clf-color-main-hovered);
}

.wrap > input:disabled + .main {
    border-color: var(--clf-border-color-disabled);
    background-color: var(--clf-background-color-disabled);
}

.wrap > input:disabled:checked + .main {
    border-color: var(--clf-border-color-disabled);
    background-color: var(--clf-background-color-disabled);
}

.wrap > input:disabled + .main:before {
    border-color: var(--clf-border-color-disabled);
}

.wrap > input:disabled:indeterminate + .main {
    border-color: var(--clf-border-color-disabled);
    background-color: var(--clf-background-color-disabled);
}

.wrap > input:focus + .main {
    box-shadow: var(--clf-shadow-outline);
}