.main {
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 24px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: left;
    gap: 5px;

    padding: 0 16px;

    box-shadow: inset 0 3px 4px rgb(0 0 0 / 10%);
    background-color: #e0e0e0;
}

.detach {
    margin-left: auto;
    width: 24px;
    height: 24px;
    border: 0;
    background: url('../../../../../public/img/icon-detach.svg') no-repeat center / 16px 16px;
    cursor: pointer;
}

.severityBlock {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: left;
    gap: 10px;

    width: 100%;

    font: 500 12px/14px Roboto, sans-serif;
}

.severityBlockTitle {
    width: 50px;
    color: #000;
    text-decoration: none;
    text-align: right;
}

.severityBlock a:not(.severityBlockTitle) {
    min-width: 35px;
    height: 16px;
    border:1px solid #333333;

    color:#ffffff;
    text-decoration: none;
    text-align: center;
    text-shadow: 1px 1px 2px #000000;
}

.highlight {
    animation: blink 1s linear 0s infinite;
}
@keyframes blink {
    50% {
        opacity: 0.5;
    }
}

/* these class names are associated with the corresponding severity levels, that is why they are capitalized */
.Unconfigured {
    background-color:#ede1b4;
}
.Suspended {
    background-color:#6666cc;
}
.Ok {
    background-color:#87cc53;
}
.Transient {
    background-color:#c3c1c0;
}
.Unknown {
    background-color:gray;
}
.Unreachable {
    background-color:#9cceff;
}
.Warning {
    background-color:#fcbd00;
}
.Critical {
    background-color:#fc392f;
}
.Fail {
    background-color:#860402;
}

.detached {
    flex-flow: column;
    gap: 10px;
    padding: 10px;
}
.detached .detach {
    display: none;
}