.middle {
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 100%;
}

.content {
    overflow: auto;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    padding: 24px 16px 16px;
}