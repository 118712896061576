@keyframes show {
    from {opacity: 0;}
    to {opacity: 1;}
}

.overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    scroll-behavior: auto;
}

.container {
    animation: show .2s ease-in-out;
}