.main {
    margin: 0;
    font-style: normal;
    color: var(--clf-text-color);
}

h1.main {
    font: var(--clf-font-title-page);
}

h2.main {
    font: var(--clf-font-title-block);
}

h3.main {
    font: var(--clf-font-title-card);
}