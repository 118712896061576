:root {
    --clf-color-main: #376667; /*rgba(55, 102, 103, 1)*/
    --clf-color-main-hovered: color-mix(in srgb, var(--clf-color-main), #000 20%);
    --clf-color-main-active: color-mix(in srgb, var(--clf-color-main), #000 40%);
    --clf-color-default: #626d7a;
    --clf-color-default-invert: white;
    --clf-color-default-subtle: var(--clf-border-color-primary);
    --clf-color-information: #166efa;
    --clf-color-success: #29a72f;
    --clf-color-success-invert: var(--clf-text-color);
    --clf-color-success-subtle: #ecfaed;
    --clf-color-warning: #f18d13;
    --clf-color-warning-invert: var(--clf-text-color);
    --clf-color-warning-subtle: #fff4e5;
    --clf-color-danger: #d44431;
    --clf-color-critical: #d22f2d;
    --clf-color-critical-invert: white;
    --clf-color-critical-subtle: #ffd2cc;
    --clf-color-overlay: rgba(54, 62, 78, 0.7);
    --clf-color-regular: var(--clf-color-default); /*TODO make sure it is not used and remove*/
    --clf-color-info: var(--clf-color-information); /*TODO make sure it is not used and remove*/
    --clf-color-ok: var(--clf-color-success); /*TODO make sure it is not used and remove*/
    --clf-color-warn: var(--clf-color-warning); /*TODO make sure it is not used and remove*/
    --clf-color-error: var(--clf-color-critical); /*TODO make sure it is not used and remove*/

    --clf-text-color: #001d30;
    --clf-text-color-secondary: #626d7a;
    --clf-text-color-disabled: #999999;

    --clf-border-color: #cccccc;
    --clf-border-color-primary: #e5e5e5;
    --clf-border-color-hovered: #999999;
    --clf-border-color-disabled: #cccccc;

    --clf-background-color-hovered: #f6f7f8;
    --clf-background-color-disabled: #f6f7f8;
    --clf-background-color-selected: rgba(55, 102, 103, .15);
    --clf-background-color-overlay: rgba(255, 255, 255, 0.75);

    --clf-font-main: 400 14px/22px Roboto;
    --clf-font-main-bolder: 500 14px/22px Roboto;
    --clf-font-main-bold: 700 14px/22px Roboto;
    --clf-font-submain: 400 14px/20px Roboto;
    --clf-font-sub: 400 12px/20px Roboto;
    --clf-font-sub-bolder: 500 12px/20px Roboto;
    --clf-font-title-page: 500 24px/28px Roboto;
    --clf-font-title-block: 500 18px/26px Roboto;
    --clf-font-title-card: 500 16px/24px Roboto;

    --clf-shadow-xs: 0 1px  3px rgba(0, 0, 0, 0.15);
    --clf-shadow-sm: 0 2px  8px rgba(0, 0, 0, 0.15);
    --clf-shadow-md: 0 8px 16px rgba(0, 0, 0, 0.20);
    --clf-shadow-lg: 0 8px 24px rgba(0, 0, 0, 0.25);
    --clf-shadow-outline: 0 0 0 2px var(--clf-color-main);
    --clf-shadow-table-header: 0 2px 3px -2px rgba(0, 0, 0, 0.15);

    --clf-gap-inherited: 32px;
}

html {
    color: var(--clf-text-color);
}

/** {
    color: inherit;
}*/

input::placeholder{
    font: var(--clf-font-main) !important;
    color: var(--clf-text-color-secondary) !important;
    opacity: 1 !important;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: var(--clf-border-color-hovered);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--clf-color-main);
}