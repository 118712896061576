.main {
    --clf-gap-inherited: 16px;

    display: flex;
    flex-direction: column;
    gap: var(--clf-gap-inherited);
    padding: 24px;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow: var(--clf-shadow-xs);
}