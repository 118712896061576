.main {
    --button-border-color: transparent;
    --button-background-color: transparent;
    --button-background-color-hovered: transparent;
    --button-background-color-active: transparent;
    --button-text-color: var(--clf-text-color);

    flex-shrink: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 4px;

    min-width: 32px;
    min-height: 32px;
    padding: 4px 15px;
    border: 1px solid var(--button-border-color);
    border-radius: 4px;

    background-color: var(--button-background-color);

    font: var(--clf-font-main-bolder);
    color: var(--button-text-color);
    white-space: nowrap;

    cursor: pointer;
    transition-property: color, background, border;
    transition-duration: 0.1s;
    transition-timing-function: linear;
    outline: none;
}

.main:hover, .main.hover {
    background-color: var(--button-background-color-hovered);
}

.main:active, .main.active {
    background-color: var(--button-background-color-active);
}

.main:focus, .main.focus {
    outline: 2px solid #BDC4FF;
}

.main:disabled, .main.disabled {
    opacity: 50%;
    cursor: not-allowed;
    pointer-events: none;
}

.main > * {
    color: currentColor !important;
    pointer-events: none;
}

.ignored {
    pointer-events: none;
}

.big {
    gap: 6px;
    min-height: 40px;
}

.small {
    min-width: 24px;
    min-height: 24px;
    padding: 3px 11px;
    font: 500 12px/16px Roboto;
}

.bulk {
    min-height: 24px;
    padding: 0 4px;
    border-radius: 2px;
    font: var(--clf-font-main);
}

.wrap {
    min-width: 24px;
    min-height: 24px;
    padding: 0;
}

.primary.default {
    --button-background-color: var(--clf-color-main);
    --button-background-color-hovered: var(--clf-color-main-hovered);
    --button-background-color-active: var(--clf-color-main-active);
    --button-text-color: #ffffff;
}

.primary.warning {
    --button-background-color: var(--clf-color-warning);
    --button-background-color-hovered: #e5b000;
    --button-background-color-active: #c29500;
}

.primary.critical {
    --button-background-color: var(--clf-color-critical);
    --button-background-color-hovered: #a82624;
    --button-background-color-active: #7e1c1b;
    --button-text-color: #ffffff;
}

.secondary.default {
    --button-border-color: var(--clf-border-color);
    --button-background-color: #fff;
    --button-background-color-hovered: #e5e5e5;
    --button-background-color-active: #dcdfe4;
}

.secondary.warning {
    --button-border-color: var(--clf-color-warning);
    --button-background-color: #fff;
    --button-background-color-hovered: #fff1c7;
    --button-background-color-active: #fff1c7;
    --button-text-color: var(--clf-color-warning);
}

.secondary.critical {
    --button-border-color: var(--clf-color-critical);
    --button-background-color: #fff;
    --button-background-color-hovered: #ffd7d7;
    --button-background-color-active: #ffb3b3;
    --button-text-color: var(--clf-color-critical);
}

.tertiary.default {
    --button-background-color-hovered: #e5e5e5;
    --button-background-color-active: #f0f1ff;
    --button-text-color: #272d44;
}

.tertiary.warning {
    --button-background-color-hovered: #fff1c7;
    --button-background-color-active: #fff1c7;
    --button-text-color: var(--clf-color-warning);
}

.tertiary.critical {
    --button-background-color-hovered: #ffd7d7;
    --button-background-color-active: #ffb3b3;
    --button-text-color: var(--clf-color-critical);
}