.wrap {
    overflow: auto;
    position: fixed;
    z-index: 100;
    right: 0;
    top: 0;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    max-width: 332px;
    background: #fff;
    box-shadow: -1px 0 7px rgba(0,0,0,0.15);
    cursor: auto;
}

.head {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 0 16px;
}

.body {
    overflow: auto;
    max-height: 100%;
    padding: 0 16px;
}

.k1Link {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    text-decoration: none;
    font-size: 14px;
    color: #56878f;
}

.k1Link:hover {
    text-decoration: underline;

}

.k1Link:hover:after {
    content: '';
    margin-left: 6px;
    width: 16px;
    height: 14px;
    background: url('../../../../../public/img/icon-detach.svg') no-repeat center / contain;
}

.close {
    margin-left: auto;
    content: '';
    width: 32px;
    height: 32px;
    border: 0;
    background: url('../../../../../public/img/icon-applauncher.svg') no-repeat center / 24px 24px;
    cursor: pointer;
}

.section {

}

.sectionTitle {
    padding: 16px 0 24px;
    font: 400 18px/22px Roboto, sans-serif;
    color: #333333;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
}

.list {
    display: flex;
    flex-flow: column;
}

.gridItem {
    overflow: hidden;
    margin-bottom: 24px;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    height: 40px;
    padding-left: 52px;
    background: no-repeat left center / 40px 40px;

    font: 400 14px/17px Roboto, sans-serif;
    text-decoration: none;
    color: #333333;
}

.gridItem > span {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.gridItemDesc {
    color: var(--clf-text-color-disabled);
}

.gridItem:hover {
    overflow: visible;
}

.gridItem:hover > span {
    overflow: visible;
    white-space: unset;
}

.sso {
    display: flex;
    flex-flow: column;
    gap: 8px;
    padding: 8px 12px 8px;
}

.ssoHint {
    font: 400 12px/20px 'Roboto';
    color: var(--clf-text-color);
}

.ssoLink {
    font: 500 14px/22px 'Roboto';
    color: var(--clf-color-main);
    text-decoration: none;
}