.header {
    flex-grow: 0;
    flex-shrink: 0;
    grid-area: app_head;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 56px;
    padding: 0 8px;
    background: var(--clf-color-main) url('../../../../../public/img/header-wave.svg') no-repeat left center / contain;
}

.logo {
    align-self: center;
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: 8px;
    height: 32px;
    width: 224px;
    background: no-repeat left center / contain;
}

.logoDef {
    filter: brightness(0) invert(1);
}

.nav {
    margin-right: auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: left;
}

.link {
    display: flex;
    align-items: center;
    padding: 0 16px;
    font: 400 14px/16px Roboto, sans-serif;
    color: #fff;
    text-decoration: none;
    transition: all 0.1s linear 0.01s;
}

.link:hover, .link:focus {
    background-color: rgba(255,255,255,.18);
}

.link.active {
    background-color: rgba(0,0,0,.35);
}

.link.marked:after {
    content: '';
    position: absolute;
    right: 11px;
    top: 11px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #d9534f;
}

.controls {
    margin-left: auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: right;
}

.controls > .link {
    position: relative;
    padding: 0 8px;
    cursor: pointer;
}

.controls > .link:before {
    content: '';
    filter: brightness(0) invert(1);
}

.link:before {
    width: 32px;
    height: 100%;
    background: no-repeat center / 24px 24px;
}

.summary:before {
    background-image: url('../../../../../public/img/icon-summary.svg');
}

.info:before {
    background-image: url('../../../../../public/img/icon-info.svg');
}

.user:before {
    margin-right: 4px;
    background-image: url('../../../../../public/img/icon-user.svg');
}

.eye:before {
    margin-right: 4px;
    background-image: url('../../../../../public/img/icon-eye.svg');
}

.k1:before {
    background-image: url('../../../../../public/img/icon-k1.svg');
}

.applauncher:before {
    background-image: url('../../../../../public/img/icon-applauncher.svg');
    background-size: 24px 24px;
}


/*.special:before {*/
/*    width: 30px;*/
/*    height: 30px;*/
/*    background: conic-gradient(from 90deg at 4px 4px, #fff 90deg,#0000 0) -2px -2px / 33.33% 33.33%;*/
/*}*/

.dropDown {
    display: flex;
    right: 0;
    top: 100%;
    flex-flow: column;
    min-width: 268px;
    position: absolute;
    z-index: 100;
    padding-bottom: 4px;
    background: #fff;
    box-shadow: var(--clf-shadow-sm);
    font: 400 14px/16px Roboto, sans-serif;
    color: rgb(51, 51, 51);
    cursor: auto;
}

.dropDownTrigger {
    user-select: none;
}

.dropDownTrigger:focus-within {
    background-color: rgba(0,0,0,.35);
}

.dropDownTrigger:not(:focus-within) > * {
    display: none;
}

.dropDownHead {
    display: flex;
    flex-flow: row nowrap;
    justify-content: left;
    align-items: center;
    padding: 20px 16px;
}

.dropDownHead:before {
    content: '';
    width: 32px;
    height: 32px;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.dropDownHead > div {
    display: flex;
    flex-flow: column;
}

.dropDownHeadTitle {
    font-weight: 600;
}

.dropDownHeadHint {
    font: 400 12px/13px Roboto, sans-serif;
    color: #757575;
}

.dropDown > hr {
    margin: 4px 0;
    border: 0;
    border-top: 1px solid #ececec;
}

.dropDownTitle {
    padding: 16px 8px 4px 16px;
    font: 400 12px/13px Roboto, sans-serif;
    color: var(--clf-text-color-secondary);
    text-transform: uppercase;
}

.dropDownLink {
    display: flex;
    align-items: center;
    min-height: 32px;
    padding: 0 16px;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}
.dropDownLinkDisabled {
    opacity: .5;
    cursor: not-allowed;
}

.dropDownTitle + div > .dropDownLink {
    padding-left: 23px;
}

.dropDownLink:hover {
    background-color: #f7f7f7;
}

.release {
    display: flex;
    gap: 8px;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
    padding: 4px 16px;
    color: inherit;
    text-decoration: none;
}

.release.hasNews:before {
    flex-shrink: 0;
    flex-grow: 0;
    content: '';
    width: 12px;
    height: 12px;
    border: 3px solid rgb(255, 255, 255, .85);
    border-radius: 50%;
    background: rgb(55, 102, 103, 1);
}

.release > span {
    display: flex;
    flex-flow: row nowrap;
    font: 400 14px/16px Roboto, sans-serif;
    color: #757575;
    white-space: nowrap;
}

.releaseButton {
    margin-left: auto;
    padding: 6px 16px 4px;
    border-radius: 2px;
    background-color: var(--clf-color-main);
    font: 500 12px/14px Roboto, sans-serif;
    text-decoration: none;
    color: #FFFFFF;
    white-space: nowrap;
}